import { isServer } from '@/configs';
import request from '../index';
import { URLS } from '@/constants/biz';
import { SGameListItemSchema, TInferSchemaType } from '@/constants/schemas';
import { isLocalDev, isRelease, isTelegramMiniApp } from '@/utils/checkRuntime';
import NodeCache from 'node-cache';
import qs from 'qs';
const gameListCache = new NodeCache({
  stdTTL: 60 * 60,
});

// Generated by https://quicktype.io

type HUB88_SUPPORTED_PLATFORMS = 'GPL_DESKTOP' | 'GPL_MOBILE' | 'GPL_TABLET';

import * as Sentry from '@sentry/nextjs';
import { CustomAxiosRequestConfig } from '@/helper/http/interceptors/types';

export type TGameListItem = TInferSchemaType<typeof SGameListItemSchema>;

// const gameListCache = {
//   games: [] as TGameListItem[],
//   lastFetchTime: 0,
// };

export const getGameList = (debugUseProductionGameList?: boolean) => {
  const useProdGameList = !isRelease && debugUseProductionGameList;
  const cacheKey = 'gameList-' + useProdGameList ? 'prod' : '';
  if (gameListCache.get(cacheKey) && isRelease) {
    return Promise.resolve(gameListCache.get<TGameListItem[]>(cacheKey));
  }
  const requestObj: CustomAxiosRequestConfig = {
    url: '/api/mc-api/game/list',
    method: 'get',
    headers: isServer
      ? {
          ['request-agent']: 'moonclub-server',
        }
      : undefined,
  };
  if (useProdGameList) {
    requestObj.baseURL = 'https://api.moonclub.com';
  }
  return request<{
    games: TGameListItem[];
  }>(requestObj)
    .then(async (d) => {
      const { data } = d;
      const enabledGames = data.games.filter((game) => game.enabled);

      /**
       * set cache
       */
      gameListCache.set(cacheKey, enabledGames);
      return enabledGames;
    })
    .catch((err) => {
      Sentry.captureMessage('Failed_To_Fetch_Game_List', {
        extra: {
          error: JSON.stringify(err),
        },
      });
      return [];
    });
};

export const getHub88GameURL = ({
  gameId,
  platform,
  country,
  ip,
  lang,
  user,
  currency,
}: {
  gameId: string;
  platform: HUB88_SUPPORTED_PLATFORMS;
  currency: string;
  country: string;
  ip: string;
  lang: string;
  user: string;
}) => {
  return request<{
    url: string;
  }>({
    url: '/api/mc-api/hub88/game/url',
    method: 'post',
    data: {
      game_code: gameId,
      platform,
      currency,
      deposit_url: URLS.depositURL(location.origin),
      lobby_url: URLS.lobbyURL(location.origin),
      country,
      ip,
      lang,
      user,
    },
  });
};

/**
 *
 * @param param0 the only place where we gonna need 'gameId'
 * @returns
 */
export const getHacksawGamingToken = ({
  gameId,
  currency = 'USDT',
}: {
  gameId: string;
  currency: string;
}) => {
  return request<{
    token: string;
    userId: string;
  }>({
    url: '/api/mc-api/hacksaw/token',
    method: 'post',
    data: {
      gameId: gameId,
      currency,
    },
  });
};
export const getSlotegratorGameUrl = ({
  gameId,
  currency,
  demo,
}: {
  gameId: string;
  currency: string;
  demo: boolean;
}) => {
  const data = {
    gameId,
    currency,
    demo,
  };
  return request<{
    url: string;
  }>({
    url: `/api/mc-api/slotegrator/init`,
    method: 'post',
    data,
  });
};
